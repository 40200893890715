import './App.css';
import Navbar from './Components/Navbar.js'
import Home from './Components/Home.js'

function App() {
  return (
    <div className="App">
      <Home/>

    </div>
  );
}

export default App;
